import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import {
  CardCabinAtardecer,
  CardCabinGaviota,
  CardCabinPerla,
  CardHabitaciones,
} from "../../components/Cards"
import Seo from "../../components/seo"

const ClimaPage = () => {
  return (
    <Layout>
      <Seo title="Habitación con clima" />

      <div className="pt-32 lg:pt-0 lg:max-w-4xl mx-auto pb-16">
        <div className="text-center pb-16">
          <h2 className="title lg:subtitle">Habitacion con Clima</h2>
          <p>Elegí la cabaña que mejor se adapte a tus necesidades.</p>
        </div>
        <div>
          <div class="overflow-x-scroll snap-x flex gap-2  ml-4">
            <div class="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={248}
                src="../../images/Clima/clima1.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div class="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={248}
                src="../../images/Clima/clima2.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div class="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={248}
                src="../../images/Clima/clima3.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div class="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={248}
                src="../../images/Clima/clima4.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div class="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={248}
                src="../../images/Clima/clima5.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div class="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={248}
                src="../../images/Clima/clima6.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
          </div>
        </div>

        <div class="snap-center flex-shrink-0 lg:block hidden">
          <StaticImage
            src="../../images/Clima/climaAll.png"
            placeholder="tracedSVG"
            alt="image of cabins"
            className="block"
          />
        </div>
        <div className="flex flex-col itmes-center py-8 gap-4 max-w-xs mx-auto">
          <a href="https://wa.me/527531154050" className="">
            <button className="button-primary max-w-xs w-full ">
              Reservar
            </button>
          </a>
          <Link to="/cabin/choose">
            <button className="button-secondary lg:hidden w-full">
              Ver mas Cabañas
            </button>
          </Link>
        </div>
        <div className="hidden lg:block h-full lg:min-h-screen pt-8 lg:pt-16">
          <h2 className="title lg:subtitle text-center">Elegi tu cabaña</h2>
          <p className="paragraph text-center py-4 lg:pb-16 hidden:lg-block">
            Elegí la cabaña que mejor se adapte a tus necesidades.
          </p>
          <div
            className={` lg:flex items-center justify-center flex-wrap gap-8 lg:gap-16 lg:max-w-6xl mx-auto py-8 lg:pb-16 px-2`}
          >
            <CardCabinGaviota />
            <CardCabinPerla />
            <CardCabinAtardecer />
            <CardHabitaciones />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ClimaPage
